<template>
  <div class="page-box box">
    <div
      class="edit-btn"
      @click="pageStatus = 'edit'"
    >
      <van-icon name="edit" />
      编辑
    </div>
    <van-form
      class="form-box"
      @submit="onSubmit"
    >
      <van-field
        readonly
        required
        clickable
        input-align="right"
        name="area"
        :value="addrStr"
        label="家庭地址"
        placeholder="点击选择省市区"
        @click="pageStatus === 'edit' ? showArea = true : ''"
      />
      <van-popup
        v-model="showArea"
        position="bottom"
      >
        <van-area
          :value="form.areaNo || ''"
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup>

      <van-field
        v-model="form.detailAddr"
        :readonly="pageStatus !== 'edit'"
        required
        input-align="right"
        label="详细地址"
        placeholder="请输入家庭详细地址"
      />
      <van-field
        readonly
        input-align="right"
        required
        clickable
        name="picker"
        :value="form.income"
        label="家庭收入"
        placeholder="点击选家庭收入"
        @click="pageStatus === 'edit' ? showPickerA = true : ''"
      />
      <van-popup
        v-model="showPickerA"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="incomeArr"
          @confirm="onConfirmA"
          @cancel="showPickerA = false"
        />
      </van-popup>
      <!-- <van-field
        readonly
        clickable
        required
        name="datetimePicker"
        :value="getTime(form.time)"
        label="时间选择"
        placeholder="点击选择时间"
        @click="showPicker = true"
      /> -->
      <van-field
        v-model="form.contractName"
        :readonly="pageStatus !== 'edit'"
        required
        input-align="right"
        name="紧急联系人"
        label="紧急联系人"
        placeholder="紧急联系人"
      />
      <van-field
        v-model="form.contractMobile"
        :readonly="pageStatus !== 'edit'"
        required
        input-align="right"
        name="紧急联系方式"
        label="紧急联系方式"
        label-width="120"
        placeholder="紧急联系方式"
      />
      <van-field
        readonly
        required
        clickable
        input-align="right"
        name="picker"
        :value="form.education"
        label="学历"
        placeholder="点击选学历"
        @click="pageStatus === 'edit' ? showPickerB = true : ''"
      />
      <van-popup
        v-model="showPickerB"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :columns="educationArr"
          @confirm="onConfirmB"
          @cancel="showPickerB = false"
        />
      </van-popup>
      <van-field
        v-model="form.companyName"
        :readonly="pageStatus !== 'edit'"
        name="公司名称"
        input-align="right"
        label="公司名称"
        required
        placeholder="输入公司名称"
        :rules="[{ required: true, message: '请填写关系' }]"
      />
      <van-field
        v-model="form.email"
        :readonly="pageStatus !== 'edit'"
        required
        name="邮箱"
        input-align="right"
        label="邮箱"
        placeholder="邮箱"
      />
    </van-form>

    <!-- <van-button
      v-show="pageStatus === 'edit'"
      class="_btn"
      color="#51B464"
      round
      type="info"
    >
      确定
    </van-button> -->

    <!-- 按钮 -->
    <div
      v-show="pageStatus === 'edit'"
      class="btn-box box"
    >
      <van-button
        class="btn"
        color="#51B464"
        plain
        @click="pageStatus = ''"
      >
        取消
      </van-button>
      <van-button
        class="btn"
        color="#51B464"
        type="info"
        @click="editBtn"
      >
        确定
      </van-button>
    </div>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  name: 'InfoIndex',
  components: {
  },
  data () {
    return {
      form: {
        name:'',
        time: '',
        relations: '',
        num: '',
        income: ''
      },
      incomeArr: [
        '5万以下',
        '5-30万',
        '30万以上'
      ],
      areaList,
      addrStr: '',
      showArea: false,
      educationArr: ['小学', '初中', '高中', '大专', '本科', '硕士', '博士及以上'],
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(),
      showPicker: false,
      showPickerA: false,
      showPickerB: false,
      pageStatus: ''
    }
  },
  computed: {
  },
  async created () {
    await this.getData()
    this.initData()
  },
  methods: {
    async getData () {
      let res = await this.$http.userApi.userInfo({})
      console.log(res, 'res')
      if (res.code === 200) {
        this.form = res.msg || {}
      }
    },
    getTime () {
      if (this.form.time) {
        return this.getTimeStr(this.form.time.getTime(), 'day')
      }
      return ''
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    // onConfirm(time, type) {
    //   if (!type) {
    //     this.form.time = time;
    //     this.showPicker = false;
    //   } else if (type === 'city') {
    //     this.showPickerA = false;
    //   }
    // },
    initData () {
      if (this.form.provinceNo) {
        this.addrStr = `${this.form.provinceName}/${this.form.cityName}/${this.form.areaName}` 
      }
    },
    async getform () {
      let res = await this.$http.orderApi.getform({courseNo: this.$route.query.id})
      this.form = res.msg || {}
    },
    onConfirm(values) {
      console.log(values, 'sss')
      this.addrStr = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.form.provinceNo = values[0].code
      this.form.cityNo = values[1].code
      this.form.areaNo = values[2].code
      this.form.provinceName = values[0].name
      this.form.cityName = values[1].name
      this.form.areaName = values[2].name
      this.showArea = false;
    },
    onConfirmA(value) {
      this.form.income = value;
      this.showPickerA = false;
    },
    onConfirmB(value) {
      this.form.education = value;
      this.showPickerB = false;
    },
    filterParams () {
      if (!this.form.email) {
        this.$toast('请填写邮箱')
      } else if (!(this.form.provinceNo)) {
        this.$toast('请选择家庭地址省市县')
      } else if (!(this.form.detailAddr)) {
        this.$toast('请填写家庭详细地址')
      } else if (!this.form.income) {
        this.$toast('请填写收入')
      } else if (!this.form.education) {
        this.$toast('请填写学历')
      } else if (!this.form.contractName) {
        this.$toast('请填写紧急联系人姓名')
      } else if (!this.form.contractMobile) {
        this.$toast('请填写紧急联系人号码')
      } else if (!this.form.companyName) {
        this.$toast('请填写公司名称')
      } else {
        return true
      }
    },
    onSubmit (e) {
      console.log(e)
    },
    async editBtn () {
      let data = this.$deepClone(this.form)
      if (this.filterParams()) {
        let res = await this.$http.userApi.editInfo(data)
        console.log(res, 'edit')
        if (res.code === 200) {
          this.$toast.success('编辑成功')
          this.pageStatus = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-top: 10px;
  .edit-btn {
    padding: 2px 0;
    text-align: right;
    line-height: 18px;
    padding-right: 15px;
    font-size: 13px;
  }
  .form-box {
    width: 100%;
  }
  ._btn {
    width: 345px;
    height: 40px;
    display: block;
    margin: 50px auto 0;
  }
  .btn-box {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 6px;
    text-align: center;
    background: #fff;
    z-index: 9;
    .btn {
      width: 166px;
      margin: 0 5px;
    }
  }
}
</style>
